import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
// eslint-disable-next-line
import UIkit from 'uikit'
// eslint-disable-next-line
import style from 'uikit/dist/css/uikit.css'
import Home from './components/Home';
import Error from './components/Error';
import './ywap.css';
import './hulp.css';




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="*" element={<Error />} />
        </Routes>
      </BrowserRouter>
  </React.StrictMode>

);


