import logo from '../img/bc_logo.svg'
import Hulp from './hulp';

function Home() {
  return (
    <div>
      <div className="uk-position-top">
        <div className="uk-container uk-container-large uk-margin-top">
          <nav className="uk-navbar-container uk-navbar-transparent" data-uk-navbar>
            <div className="uk-navbar-left">
              <img src={logo} alt='logo' />
            </div>
          </nav>
        </div>
      </div>
      <div className="uk-section uk-section-small uk-height-viewport uk-flex uk-flex-middle bg">
        <div className="uk-container uk-container-large">

          {/* <div className="uk-position-top">
          <div className="uk-container">
            logo
          </div>
        </div> */}
          <div data-uk-grid className="uk-grid-small uk-flex uk-flex-middle">
            <div className="uk-width-3-5@m ">
              <p className="pill">GIVE</p>
              <h1 className="headline">Be a part of our story!</h1>
              <p className="subtext">The 25th Anniversary of YWAP, to be celebrated at boothcamp is just 5 months away, and your contribution can make this event truly unforgettable.</p>
              {/* <button className="btn">The Boothcamp Story</button> */}
              <button className="btn" data-uk-toggle="target: #bc-story">The Boothcamp Story</button>

              <div id="bc-story" class="uk-modal-container" data-uk-modal>
                <div class="uk-modal-dialog uk-modal-body">
                  <button class="uk-modal-close-default" type="button" data-uk-close></button>
                  <h2 class="modal-header">The Boothcamp story</h2>
                  <p className='modal-sub'>How it started </p>
                  <p className='modal-p'>Booth Camp holds a special place in the hearts of YWAP members. In 2004, there was a recognized need for an ongoing connection among participants. What was envisioned was a space for yearly gathering, reconnection, and spiritual refueling. From this vision, Booth Camp was born, and its impact continues to grow. In the world of youth ministry, Booth Camp stands apart. It's more than a week of a scenic retreat. It's an incubator. A rallying point.
                  </p>
                  <p className='modal-sub'>Why the name?
                  </p>
                  <p className='modal-p'>Drawing its name and philosophy from the Biblical story of the Israelites dwelling in temporary shelters (booths) after leaving Egypt, Booth Camp creates a space of focus and recalibration. More than just inspiring sermons, it's a week of introspection, skill-building, and networking. This is where friendships are ignited, and where participants gain real-world tools alongside spiritual nourishment.
                    <br /><br />
                    The core element of Booth Camp is about building bonds. "There's a sense of camaraderie that forms," a long-term organizer explains,
                    <br /><br />

                    “When you're worshiping, working, and overcoming challenges together, something beautiful happens."
                    <br /><br />

                    The impact isn't just about the here and now; it's a seed that's designed for a bountiful future. Those who attend Booth Camp leave with a support network and a deepened drive to serve their communities guided by their individual journeys of faith.</p>
                  <p className='modal-sub'>Looking to the Future
                  </p>
                  <p className='modal-p'>What began as a gathering of a few dozen has expanded into a thriving retreat welcoming hundreds. Organizers are excited by this growth and have ambitious goals. The plan is for Booth Camp to become a global touchstone, welcoming young people from all backgrounds in an atmosphere of spiritual learning and shared purpose.
                  </p>
                  <p className='modal-p'>Booth Camp embodies the belief that true faith requires action. If you're searching for personal transformation, meaningful community, and tools to change your world, this could be the experience with your name on it.
                  </p>
                  <p className='modal-p'>Let Booth Camp be a catalyst for your journey. Be part of building an army of positive change.</p>
                </div>
              </div>

              <p className="subtext">Together, let's celebrate 25 years of YWAP and make this Boothcamp a landmark event!</p>
            </div>
            <div className="uk-width-2-5@m">
              <Hulp />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
