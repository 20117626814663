import React from 'react';
import { FlutterWaveButton, closePaymentModal } from 'flutterwave-react-v3';
import axios from 'axios';
import { API_URL } from '../apiURL'
import Loader from './Loader'
import paid from '../img/paid.png'


// import axios from 'axios';


import ButtonLoader from './ButtonLoader'
import hulp_badge from '../img/hulp_badge.svg'

class Hulp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isProcessing: true,
            reccurentCompleted: false,
            one_timeCompleted: false,
            reccurent_name: '',
            reccurent_email: '',
            reccurent_amount: 20000,
            payment_plan: 119850,
            one_time_amount: 0,
            one_time_name: '',
            one_time_email: '',
            giving: [],
        }
    }

    componentDidMount() {
        this.setState({ isProcessing: false })
    }

    inputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    amountChange = (amount) => {
        this.setState({ reccurent_amount: amount })

        if (amount === 20000) { this.setState({ payment_plan: 119850 }) }
        if (amount === 50000) { this.setState({ payment_plan: 119851 }) }
        if (amount === 100000) { this.setState({ payment_plan: 119855 }) }

    }

    generateDonorId() {
        const timestamp = new Date().getTime();
        const random = Math.floor(Math.random() * 10000); // Adjust this range as needed
        return `${timestamp}-${random}`;
    }

    payReccurrent = (e) => {
        e.preventDefault();
        this.setState({ isProcessing: true })
    }

    payOneTime = (e) => {
        e.preventDefault();
        this.setState({ isProcessing: true })
    }

    verifygivingReccurent = (resp) => {
        this.setState({ isProcessing: true })
        axios.post(API_URL + '/api/verifygiving/', resp)
            .then((response) => {
                this.setState({ giving: response.data, reccurentCompleted: true, isProcessing: false })
            })
            .catch((e) => {
                if (e.response) {
                    console.log(e);
                }
            });

    }


    verifygivingOneTime = (resp) => {
        this.setState({ isProcessing: true })
        axios.post(API_URL + '/api/verifygiving/', resp)
            .then((response) => {
                this.setState({ giving: response.data, one_timeCompleted: true, isProcessing: false })
            })
            .catch((e) => {
                if (e.response) {
                    console.log(e);
                }
            });

    }




    render() {

        const reccurentconfig = {
            public_key: 'FLWPUBK-156b91580b16f2a7b51994d6adbad19b-X',
            tx_ref: Date.now(),
            amount: this.state.reccurent_amount,
            currency: 'NGN',
            subaccounts: [
                {
                    id: "RS_98BEEE6B32FA3989C918C7FEFFF61C6A",
                    transaction_charge_type: "flat_subaccount",
                    transaction_charge: this.state.reccurent_amount - (this.state.reccurent_amount * 0.05),
                }
            ],
            payment_options: 'card,ussd',
            payment_plan: this.state.payment_plan,
            customer: {
                email: this.state.reccurent_email,
                phonenumber: '',
                name: this.state.reccurent_name,
            },
            meta: {
                payment_type: 'donation',
                donor_id: this.generateDonorId()
            },
            customizations: {
                title: "YWAP Boothcamp BCXVIII Giving",
                description: "Give ₦" + this.state.reccurent_amount.toLocaleString() + "to YWAP Boothcamp for 5 Months",
                logo: "https://res.cloudinary.com/ndirpaya/image/upload/v1714361920/ywap/ywap_bc_logo.svg",
            },
        };

        const fwReccurentConfig = {
            ...reccurentconfig,
            text: this.state.isProcessing ? <ButtonLoader /> : 'Donate Now',
            // text:'Donate Now - flw',

            callback: (response) => {
                closePaymentModal()
                this.verifygivingReccurent(response)
                // console.log("payment response", response)
            },
            onClose: (incomplete) => {
                console.log('Close: ', incomplete)
                this.setState({ isProcessing: false })

            },
        };


        const onetimeconfig = {
            public_key: 'FLWPUBK-156b91580b16f2a7b51994d6adbad19b-X',
            tx_ref: Date.now(),
            amount: this.state.one_time_amount,
            currency: 'NGN',
            subaccounts: [
                {
                    id: "RS_98BEEE6B32FA3989C918C7FEFFF61C6A",
                    transaction_charge_type: "flat_subaccount",
                    transaction_charge: this.state.one_time_amount - (this.state.one_time_amount * 0.05),
                }
            ],
            payment_options: 'card,ussd',
            customer: {
                email: this.state.one_time_email,
                phonenumber: '',
                name: this.state.one_time_name,
            },
            meta: {
                payment_type: 'donation',
                donor_id: this.generateDonorId()
            },
            customizations: {
                title: "YWAP Boothcamp BCXVIII Giving",
                description: "Give ₦" + this.state.one_time_amount.toLocaleString() + "to YWAP Boothcamp ",
                logo: "https://res.cloudinary.com/ndirpaya/image/upload/v1714361920/ywap/ywap_bc_logo.svg",
            },
        };

        const fwOneTimeConfig = {
            ...onetimeconfig,
            text: this.state.isProcessing ? <ButtonLoader /> : 'Donate Now',
            // text:'Donate Now - flw',

            callback: (response) => {
                closePaymentModal()
                this.verifygivingOneTime(response)
                // console.log("payment response", response)
            },
            onClose: (incomplete) => {
                console.log('Close: ', incomplete)
                this.setState({ isProcessing: false })

            },
        };


        return (
            <div className='container'>
                {this.state.reccurentCompleted
                    ?
                    <div className='uk-text-center'>
                        <img src={paid} alt='paid' className='paid-img' />
                        <h5 className='hulp-h4'>{this.state.giving.name},</h5>
                        <h5 className='hulp-h4 uk-margin-medium-top'>Thank you for giving</h5>
                        <h5 className='hulp-h3'>₦{this.state.giving.amount.toLocaleString()}</h5>
                        <h5 className='hulp-h4'>to Youth With a Purpose Initiative</h5>
                        <h5 className='hulp-h4 uk-margin-large-top'>This will be collected reccurently monthly for the next 4 months</h5>
                        <div className='uk-width-1-1@m uk-text-center uk-margin-large-top'>
                            <a href="https://hulp.fund" target='blank_'><img src={hulp_badge} alt='hulp badge' /></a>
                        </div>
                    </div>
                    :
                    null
                }

                {this.state.one_timeCompleted
                    ?
                    <div className='uk-text-center'>
                        <img src={paid} alt='paid' className='paid-img' />
                        <h5 className='hulp-h4'>{this.state.giving.name},</h5>
                        <h5 className='hulp-h4 uk-margin-medium-top'>Thank you for giving</h5>
                        <h5 className='hulp-h3'>₦{this.state.giving.amount.toLocaleString()}</h5>
                        <h5 className='hulp-h4'>to Youth With a Purpose Initiative</h5>
                        <div className='uk-width-1-1@m uk-text-center uk-margin-large-top'>
                            <a href="https://hulp.fund" target='blank_'><img src={hulp_badge} alt='hulp badge' /></a>
                        </div>
                    </div>
                    :
                    null

                }

                {this.state.isProcessing
                    ?
                    <Loader isLoading={this.state.isProcessing} />
                    :
                    null
                }
                {!this.state.reccurentCompleted && !this.state.one_timeCompleted && !this.state.isProcessing
                    ?
                    <div>
                        <p className='hulp-p'>We understand that donating a large sum at once can be challenging. That's why we've created a convenient way to spread your donation across four monthly installments.</p>
                        <ul className="uk-subnav uk-subnav-pill hulp-switcher" data-uk-switcher="connect: #hulp-payment-form; animation: uk-animation-fade">
                            <li><a href="/">Monthly</a></li>
                            <li><a href="/">One-time</a></li>
                        </ul>

                        <ul id='hulp-payment-form' className="uk-switcher uk-margin">
                            <li>
                                <div>
                                    <h5 className='hulp-h5'>I will give <span className='b'>₦{this.state.reccurent_amount.toLocaleString()}</span> for 4 months</h5>
                                    <ul className="uk-subnav uk-subnav-pill hulp-amount-switcher" data-uk-switcher>
                                        <li><a onClick={() => this.amountChange(20000)} href="/"  >₦20,000</a></li>
                                        <li><a onClick={() => this.amountChange(50000)} href="/" >₦50,000</a></li>
                                        <li><a onClick={() => this.amountChange(100000)} href="/" >₦100,000</a></li>
                                    </ul>
                                    {/* <h5 className='hulp-h5'>for 5 months</h5> */}
                                    <form id='reccurent_paymentform' className="uk-grid-small uk-margin-top" data-uk-grid method='POST' onSubmit={this.payReccurrent}>
                                        <div className="uk-margin-small uk-width-1-1@m">
                                            <input className="uk-input uk-hulp-input" id="reccurent_name" name='reccurent_name' type="text" placeholder='Name' onChange={this.inputChange} required />
                                        </div>
                                        <div className="uk-margin-small uk-width-1-1@m">
                                            <input className="uk-input uk-hulp-input" id="reccurent_email" name='reccurent_email' type="email" placeholder='Email' onChange={this.inputChange} required />
                                        </div>
                                        <div className="uk-margin-small uk-width-1-1@m">
                                            {this.state.reccurent_amount > 0 && this.state.reccurent_name !== '' && this.state.reccurent_email !== ''
                                                ?
                                                <FlutterWaveButton className="uk-button uk-hulp-button uk-width-1-1@m" {...fwReccurentConfig} />
                                                :
                                                <button form='reccurent_paymentform' className="uk-button uk-hulp-button uk-width-1-1" type='submit'>
                                                    Donate Now
                                                </button>
                                            }

                                        </div>
                                    </form>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <form id='one_time_paymentform' className="uk-grid-small uk-margin-top" data-uk-grid method='POST' onSubmit={this.payOneTime}>
                                        <div className="uk-margin-small uk-width-1-1@m">
                                            <input className="uk-input uk-hulp-input" id="one_time_amount" name='one_time_amount' type="number" placeholder='(₦) Amount' onChange={this.inputChange} required />
                                        </div>
                                        <div className="uk-margin-small uk-width-1-1@m">
                                            <input className="uk-input uk-hulp-input" id="one_time_name" name='one_time_name' type="text" placeholder='Name' onChange={this.inputChange} required />
                                        </div>
                                        <div className="uk-margin-small uk-width-1-1@m">
                                            <input className="uk-input uk-hulp-input" id="one_time_email" name='one_time_email' type="email" placeholder='Email' onChange={this.inputChange} required />
                                        </div>
                                        <div className="uk-margin-small uk-width-1-1@m">
                                            {this.state.one_time_amount > 0 && this.state.one_time_name !== '' && this.state.one_time_email !== ''
                                                ?
                                                <FlutterWaveButton className="uk-button uk-hulp-button uk-width-1-1@m" {...fwOneTimeConfig} />
                                                :
                                                <button form='reccurent_paymentform' className="uk-button uk-hulp-button uk-width-1-1" type='submit'>
                                                    Donate Now
                                                </button>
                                            }

                                        </div>
                                    </form>

                                </div>
                            </li>
                        </ul>
                        <div className='uk-width-1-1@m uk-text-center'>
                            <a href="https://hulp.fund" target='blank_'><img src={hulp_badge} alt='hulp badge' /></a>
                        </div>
                    </div>
                    :
                    null
                }
            </div>
        )
    }
}

export default Hulp